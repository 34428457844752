import React from "react";
import { Link } from "react-router-dom";
import abimg from "../../images/marketing-agency.jpg";
import "./style.css";

const About = (props) => {
  return (
    <section className='about-us-section-s2 section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-7'>
            <div className='section-title-s6 text-left'>
              <h2>About us</h2>
              <p>
                <b>Sian Development & Advertising</b> (SDA) is a company based
                on several activities focus on marketing and advertising
                development.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-12'>
            <div className='feature-grids clearfix'>
              <div className='grid'>
                <i className='fi flaticon-diamond'></i>
                <h3>Web Agency</h3>
                <p>Web development & design</p>
              </div>
              <div className='grid'>
                <i className='fi flaticon-aim'></i>
                <h3>Performance Advertising</h3>
                <p>
                  Selling leads to e-commerce company, it’s performance
                  advertising (= the client pays only if we generate a lead, he
                  does not pay if it’s just a visitor). We generate leads via
                  campaign on Google, Bing, Display, emails …
                </p>
              </div>
              <div className='grid'>
                <i className='fi flaticon-mail'></i>
                <h3>Marketing Optimisation</h3>
                <p>
                  we improve the purchase funnel for e-commerce website, to
                  improve the business of the ecommerce website
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
