import React from "react";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";

import "./style.css";
import simg from "../../images/web-agency.svg";
import simg2 from "../../images/seo.svg";
import simg3 from "../../images/undraw_referral_4ki4.svg";
import simg4 from "../../images/optimize.svg";
import schema from "../../images/schema.png";

const FunFact = (props) => {
  return (
    <>
      <section className='faq-funfact-section section-padding mt-5 pt-3 pb-0'>
        <div className='container'>
          <div className='row'>
            <div className='col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1'>
              <div className='section-title-s8 text-center'>
                <h2>Our services</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Element name='agency'>
        <section className='services-section-s2 section-padding pt-0'>
          <div className='container'>
            <div className='row'>
              <div className='col col-12'>
                <div className='section-title-s8 text-center'>
                  <h3>Web Agency</h3>
                  <h5 className='primary'>
                    SDA provides Web Development Solutions for your Company
                  </h5>
                </div>
                <p>
                  <b>
                    We offer web design and page programming services and we
                    work so that potential clients reach your website or
                    e-commerce website.
                  </b>
                </p>
                <p>
                  We take the time to get to know not only your project, but
                  also your company and your industry -> This allows us to be
                  able to use the right tools, technologies, and frameworks to
                  accomplish your goals.s
                </p>

                <p>
                  <b>
                    We build both commercial e-commerce websites designed to
                    optimize customer satisfaction and drive sales, as well as
                    internal web apps for aiding in business automation.
                  </b>
                </p>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col col-lg-4 col-12'>
                <div className='service-grids'>
                  <div className='grid'>
                    <div className='icon'>
                      <i className='fi flaticon-rocket'></i>
                    </div>
                    <h3>
                      <Link to='/#'>UX and Creative Design</Link>
                    </h3>
                    <p>
                      SDA has a proven track record of producing excellent
                      quality web designs. We offer:
                    </p>
                    <ul>
                      <li>
                        <p>
                          Stakeholder engagement, audience & competitor analysis
                        </p>
                      </li>
                      <li>
                        <p>Information architecture</p>
                      </li>
                      <li>
                        <p>Creative graphic design & wireframing</p>
                      </li>
                      <li>
                        <p>UX testing - eye tracking & mobile testing</p>
                      </li>
                      <li>
                        <p>Print & brand design and production</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col col-lg-4 col-12'>
                <div className='img-holder'>
                  <img src={simg} alt='' />
                </div>
              </div>
              <div className='col col-lg-4 col-12'>
                <div className='service-grids right-col'>
                  <div className='grid'>
                    <div className='icon'>
                      <i className='fi flaticon-facebook'></i>
                    </div>
                    <h3>
                      <Link to='/'>Project Management</Link>
                    </h3>
                    <p>We can help your organisation:</p>
                    <ul>
                      <li>
                        <p>Integrate your existing systems</p>
                      </li>
                      <li>
                        <p>Replace legacy software</p>
                      </li>
                      <li>
                        <p>Plan, scope & develop bespoke applications</p>{" "}
                      </li>
                      <li>
                        <p>Write software/API specification documents</p>{" "}
                      </li>
                      <li>
                        <p>
                          Take your software onto the cloud or online systems
                        </p>
                      </li>
                      <li>
                        <p>Work with 3rd party system providers</p>
                      </li>
                      <li>
                        <p>Write APIs to connect systems</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <Element name='advertising'>
        <section className='services-section-s2 section-padding pt-0 service-section-mid'>
          <div className='container'>
            <div className='row'>
              <div className='col col-12'>
                <div className='section-title-s8 text-center'>
                  <h3>Performance Advertising</h3>
                  <h5 className='primary'>
                    SDA is a specialist in Lead Generation: “From Lead to
                    customer !” is our focus.
                  </h5>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col col-lg-6 col-12'>
                <div className='img-holder'>
                  <img src={simg3} alt='' />
                </div>
              </div>
              <div className='col col-lg-6 col-12'>
                <p>
                  <b>
                    Every organization wants more leads. But what are the best
                    leads? And what is a lead worth?
                  </b>
                </p>
                <p>
                  As a specialist in the field of digital lead generation, we
                  bring different fields of expertise together: marketing
                  strategy, content marketing and marketing automation. We have
                  everything you need to manage leads, evaluate their potential
                  and turn them into sales.
                </p>
                <p>
                  By moving up from thinking in groups of people to recognizing
                  individuals, you get a lot more out of your leads.
                </p>
                <div className='img-holder max-3'>
                  <img src={schema} alt='' />
                </div>
                <p>
                  <b>
                    Not only can we plan, design and develop your new website,
                    we also help our clients build traffic and increase
                    business, sales and conversions.
                  </b>
                </p>
              </div>

              <div className='row my-4 mt-5 pt-5'>
                <div className='col col-lg-7 col-12'>
                  <p>
                    An SEO strategy is an absolute essential for all brands
                    online. No matter how big or small your website, optimising
                    on-page copy and meta-data will help you climb up Google's
                    natural search results.
                  </p>
                  <p>SDA helps on:</p>
                  <ul>
                    <li>
                      <p>Analyse your keyword data.</p>
                    </li>
                    <li>
                      <p>
                        Optimise your website to boost your rankings quickly and
                        sustainably.
                      </p>
                    </li>
                    <li>
                      <p>
                        Ensure more visitors will see your listing and visit
                        your page.
                      </p>
                    </li>
                  </ul>
                  <p>
                    We build websites considering all stakeholders—including
                    search engines.
                  </p>

                  <div className='why-choose-us-s1'>
                    <div className='why-choose-grids'>
                      <div className='grid'>
                        <div className='icon'>
                          <i className='fi flaticon-diamond'></i>
                        </div>
                        <p>SEO and Conversion Rate Optimization</p>
                      </div>
                      <div className='grid'>
                        <div className='icon'>
                          <i className='fi flaticon-diamond'></i>
                        </div>
                        <p>Digital marketing audits</p>
                      </div>
                      <div className='grid'>
                        <div className='icon'>
                          <i className='fi flaticon-diamond'></i>
                        </div>
                        <p>Marketing optimization retainer</p>
                      </div>
                      <div className='grid'>
                        <div className='icon'>
                          <i className='fi flaticon-diamond'></i>
                        </div>
                        <p>Remarketing campaigns</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col-lg-5 col-12'>
                  <div className='img-holder'>
                    <img src={simg2} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <Element name='marketing'>
        <section className='services-section-s2 section-padding pt-0'>
          <div className='container'>
            <div className='row'>
              <div className='col col-12'>
                <div className='section-title-s8 text-center'>
                  <h3>Marketing Optimisation</h3>
                  <h5 className='primary'>
                    Visualize how successful you are in converting your visitors
                    into customers. Optimize your visitors' experience and
                    increase your conversions.
                  </h5>
                </div>
              </div>
              <div className='col col-12'>
                <p>
                  We improve the purchase funnel for ecommerce website, to
                  improve the business of the ecommerce website. Let's face it,
                  bringing visitors to your website is no easy feat, specially
                  nowadays.
                </p>
                <p>
                  You have spent hours and hours crafting a wonderful campaign,
                  spending a lot of resources and money. Setting up funnels
                  leading up to the transaction you want visitors to commit will
                  allow you to understand where you visitors drop in the path to
                  conversion.
                </p>
              </div>
              <div className='col col-lg-5 col-12'>
                <div className='img-holder'>
                  <img src={simg4} alt='' />
                </div>
              </div>
              <div className='col col-lg-7 col-12'>
                <p>
                  But what do you do after discovering a 70% drop before
                  completing the transaction? SDA will be helpful to you.
                </p>
                <div className='why-choose-us-s1'>
                  <div className='why-choose-grids'>
                    <div className='grid'>
                      <div className='icon'>
                        <i className='fi flaticon-diamond'></i>
                      </div>
                      <p>
                        Instantly-built heatmaps to analyse how your visitors
                        interact with your website
                      </p>
                    </div>
                    <div className='grid'>
                      <div className='icon'>
                        <i className='fi flaticon-diamond'></i>
                      </div>
                      <p>
                        Records every visit on your website, visualize where all
                        your visitors struggle while navigating your page.
                      </p>
                    </div>
                    <div className='grid'>
                      <div className='icon'>
                        <i className='fi flaticon-diamond'></i>
                      </div>
                      <p>
                        Form analytics : Build funnels for the forms on your
                        website to understand what field is preventing your
                        leads from converting.
                      </p>
                    </div>
                    <div className='grid'>
                      <div className='icon'>
                        <i className='fi flaticon-diamond'></i>
                      </div>
                      <p>
                        Feedback campaigns : You can create custom, interactive
                        surveys to target a specific audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
    </>
  );
};

export default FunFact;
