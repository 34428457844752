import React from "react";
import { Link } from "react-scroll";

import Logo from "../../images/logo.png";
// import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import "./style.css";

const Header = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className='middle-header header-style-3'>
      <div className='container-fluid'>
        <div className='header-content'>
          <div className='logo'>
            <Link to='home' spy={true} smooth={true}>
              <img src={Logo} alt='' />
            </Link>
          </div>
          <nav className='d-lg-block d-none'>
            <ul>
              <li>
                <Link activeClass='active' to='about' spy={true} smooth={true}>
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  activeClass='active'
                  to='services'
                  spy={true}
                  smooth={true}
                >
                  Services
                </Link>

                <ul>
                  <li>
                    <Link
                      activeClass='active'
                      to='agency'
                      spy={true}
                      smooth={true}
                    >
                      Web Agency
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass='active'
                      to='advertising'
                      spy={true}
                      smooth={true}
                    >
                      Performance Advertising
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass='active'
                      to='marketing'
                      spy={true}
                      smooth={true}
                    >
                      Marketing Optimisation
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  activeClass='active'
                  to='philosophy'
                  spy={true}
                  smooth={true}
                >
                  Our Philosophy
                </Link>
              </li>
              <li>
                <Link
                  activeClass='active'
                  to='contact'
                  spy={true}
                  smooth={true}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <MobileMenu />
      </div>
    </div>
  );
};

export default Header;
