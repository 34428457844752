import React, { Fragment } from "react";
import { Element } from "react-scroll";

import Header from "../../components/header";
import Hero from "../../components/hero";
import Hero3 from "../../components/hero3";
import About from "../../components/about";
import TipsTricks from "../../components/TipsTricks";
import PartnerSlider from "../../components/partner";
import Footer from "../../components/footerTop";
import Scrollbar from "../../components/scrollbar";
import FunFact from "../../components/funfact";
import Contactpage from "../../components/Contactpage";

const HomePage = () => {
  return (
    <Fragment>
      <Header />
      <Element name='home'>
        <Hero3 />
      </Element>
      <Element name='about'>
        <About name='about' />
      </Element>
      <Element name='services'>
        <FunFact />
      </Element>
      <Element name='philosophy'>
        <TipsTricks />
      </Element>
      <PartnerSlider />
      <Element name='contact'>
        <Contactpage />
      </Element>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
