import React from "react";
import rkt1 from "../../images/boost-traffic.jpg";
import { Link } from "react-scroll";

const Hero3 = (props) => {
  return (
    <section className='hero-slider hero-style-3'>
      <div className='hero-container'>
        <div className='hero-inner'>
          <div className='container'>
            <div className='slide-title'>
              <h2>Get More Traffic </h2>
              <span>Digital Marketing Agency </span>
            </div>
            <div className='slide-text'>
              <p>
                Boost traffic to your website or local store, and find better
                qualified leads
              </p>
            </div>
            <div className='clearfix'></div>
            <div data-swiper-parallax='500' className='slide-btns'>
              <Link
                activeClass='active'
                to='about'
                spy={true}
                smooth={true}
                className='theme-btn'
              >
                More About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='hero-s3-vec'>
        <img src={rkt1} alt='' />
      </div>
    </section>
  );
};

export default Hero3;
