import React from "react";
import tp1 from "../../images/philo.jpg";
import tp2 from "../../images/check.png";

import "./style.css";

const TipsTricks = (props) => {
  return (
    <section className='tips-tricks-section section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-md-1'>
            <div className='section-title-s6'>
              <h2>Our philosophy</h2>
              <h5>Increase your return on investment!</h5>
              <p>
                Clients acquisition is becoming more and more expensive. This is
                exactly why retention marketing has become very effective. Stop
                focusing of how to attract new customers but concentrate on how
                you can make existing clients consume frequently and offer you a
                better profitability.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-lg-6 col-md-12 col-12'>
            <div className='img-holder'>
              <img src={tp1} alt='' />
            </div>
          </div>
          <div className='col col-lg-6 col-md-12 col-12'>
            <div className='tips-trick-grids'>
              <div className='grid'>
                <div className='icon'>
                  <img src={tp2} alt='' />
                </div>
                <h4>Be attractive</h4>
                <p>
                  Attract new clients (acquire the maximum of qualified traffic
                  by working on social media, digitals channels…)
                </p>
              </div>
              <div className='grid'>
                <div className='icon'>
                  <img src={tp2} alt='' />
                </div>
                <h4>Be focus</h4>
                <p>
                  Focus on your development (interest the user, get them to the
                  action, discover the use value of your products: newsletters,
                  subscription…)
                </p>
              </div>
              <div className='grid'>
                <div className='icon'>
                  <img src={tp2} alt='' />
                </div>
                <h4>Be efficient</h4>
                <p>
                  Increase the number and the value of your customers (We design
                  and set up campaigns to convert your prospects into customers
                  and thus generate income for your business, ROI analytics
                  monitoring, increase in conversion rates, customer
                  satisfaction…) – Increase your profitability is our focus !
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TipsTricks;
