import React from "react";

import "./style.css";

const Footer = (props) => {
  return (
    <footer className={`site-footer ${props.subclass}`}>
      <div className='lower-footer'>
        <div className='container'>
          <div className='row'>
            <div className='separator'>
            </div>
            <div className='col col-xs-12'>
              <p className='text-center'>
              <p>SIAN DEVELOPMENT & ADVERTISING LTD</p> 
              <p>39 Centre Point Triq Francis Zammit</p>
              <p>St Julians MALTA</p>
              <p>Company registration number C95890</p>
              <p><i>Copyright &copy; 2021 SDA. All rights reserved.</i></p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
