import React from "react";
import ContactForm from "../ContactForm";
import "./style.css";

const Contactpage = () => {
  return (
    <div className='contact-page-area'>
      <div className='contact-section-s3'>
        <div className='container'>
          <div className='row'>
            <div className='col offset-lg-2 col-lg-8 col-12'>
              <div className='section-title-s6'>
                <div className='icon'>
                  <i className='fi flaticon-balance'></i>
                </div>
                <h2>Contact Us</h2>
                <h5>Speak about your project</h5>
              </div>
              <div className='contact-form'>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;
