import React from "react";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action='https://formspree.io/xvowdrww'
        method='POST'
      >
        <div className='col-12 col-lg-6'>
          <input type='text' placeholder='Your Name*' id='fname' name='name' />
        </div>
        <div className='col col-lg-6'>
          <input
            type='text'
            placeholder='Your company'
            id='company'
            name='company'
          />
        </div>
        <div className='col col-lg-6'>
          <input type='text' placeholder='Phone' id='number' name='number' />
        </div>
        <div className='col-12  col-lg-6'>
          <input
            type='email'
            placeholder='Your Email'
            id='email'
            name='email'
          />
        </div>
        <div className='col-12 col-sm-12 full'>
          <textarea
            className='contact-textarea'
            placeholder='Message'
            name='notes'
          ></textarea>
        </div>
        <div className='col-12'>
          {status === "SUCCESS" ? (
            <p>Thanks!</p>
          ) : (
            <button type='submit' className='theme-btn-s4'>
              Send
            </button>
          )}
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </div>
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
