import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-scroll";

import "./style.css";

const menus = [
  {
    id: 1,
    title: "About Us",
    link: "about",
  },
  {
    id: 7,
    title: "Services",
    link: "services",
    submenu: [
      {
        id: 71,
        title: "Web Agency",
        link: "agency",
      },
      {
        id: 72,
        title: "Performance Advertising",
        link: "advertising",
      },
      {
        id: 73,
        title: "Marketing Optimisation",
        link: "marketing",
      },
    ],
  },
  {
    id: 3,
    title: "Our Philosophy",
    link: "philosophy",
  },

  {
    id: 5,
    title: "Contact",
    link: "contact",
  },
];

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

          <ul className='responsivemenu'>
            {menus.map((item) => {
              return (
                <li key={item.id}>
                  {item.submenu ? (
                    <p onClick={this.setIsOpen(item.id)}>
                      {item.title}
                      {item.submenu ? (
                        <i className='fa fa-angle-right' aria-hidden='true'></i>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <Link
                      activeClass='active'
                      to={item.link}
                      spy={true}
                      smooth={true}
                    >
                      {item.title}
                    </Link>
                  )}
                  {item.submenu ? (
                    <Collapse isOpen={item.id === isOpen}>
                      <Card>
                        <CardBody>
                          <ul>
                            {item.submenu.map((submenu) => (
                              <li key={submenu.id}>
                                <Link
                                  activeClass='active'
                                  to={item.link}
                                  spy={true}
                                  smooth={true}
                                >
                                  {submenu.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div className='showmenu' onClick={this.menuHandler}>
          <i className='fa fa-bars' aria-hidden='true'></i>
        </div>
      </div>
    );
  }
}
